
























































































import { Vue, Component, Prop } from "vue-property-decorator";
import Order from "@/models/order";
import DownloadReceiptButton from "@/components/DownloadReceiptButton.vue";
import {
    mdiCheckCircle,
    mdiChevronDown,
    mdiChevronUp,
    mdiCircle,
    mdiPlus,
} from "@mdi/js";
@Component({
    components: { DownloadReceiptButton },
})
export default class OrderCard extends Vue {
    @Prop({ required: true, type: Object }) order!: Order;
    showTimeline = false;
    plusIcon: string = mdiPlus;
    dotIcon: string = mdiCircle;
    upIcon: string = mdiChevronUp;
    downIcon: string = mdiChevronDown;
    tickIcon: string = mdiCheckCircle;
    toggleShowTimeline() {
        this.showTimeline = !this.showTimeline;
    }
}
