import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"order-card"},[_c(VCardTitle,{staticClass:"px-6"},[_c('span',[_c(VSkeletonLoader,{attrs:{"type":"card-heading,chip"}})],1)]),_c(VSkeletonLoader,{attrs:{"type":"heading"}}),_c(VCardText,{staticClass:"d-flex px-6 flex-wrap justify-start"},[_c(VCol,[_c(VRow,{staticClass:"text-uppercase subtitle-2"},[_c(VSkeletonLoader,{attrs:{"type":"text"}})],1),_c(VRow,[_c(VSkeletonLoader,{attrs:{"type":"text"}})],1)],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c(VCol,[_c(VRow,{staticClass:"text-uppercase subtitle-2"},[_c(VSkeletonLoader,{attrs:{"type":"text"}})],1),_c(VRow,[_c(VSkeletonLoader,{attrs:{"type":"text"}})],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mdAndUp)?_c(VCol,[_c(VRow,{staticClass:"text-uppercase subtitle-2"},[_c(VSkeletonLoader,{attrs:{"type":"text"}})],1),_c(VRow,[_c(VSkeletonLoader,{attrs:{"type":"text"}})],1)],1):_vm._e(),_c(VCol,[_c(VRow,{staticClass:"text-uppercase subtitle-2"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',[_c(VSkeletonLoader,{attrs:{"type":"text"}})],1):_c('span',[_c(VSkeletonLoader,{attrs:{"type":"text"}})],1)]),_c(VRow,[_c(VSkeletonLoader,{attrs:{"type":"text"}})],1)],1)],1),_c(VCardActions,{staticClass:"pr-6"},[_c(VSkeletonLoader,{attrs:{"type":"button"}}),_c(VSpacer),_c(VSkeletonLoader,{attrs:{"type":"button"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }