











import { Vue, Component, Prop } from "vue-property-decorator";
import Order from "@/models/order";

@Component
export default class OrderQuantity extends Vue {
    @Prop({ required: true, type: Object }) order!: Order;
}
